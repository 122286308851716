.upbox-navbar {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 10px 10px;
}

.upbox-navbar.light {
    /* background: #1c272b5c; */
    position: fixed;
    width: 100%;
    z-index: 1;
}

.upbox-navbar.dark {
    background: #1c272b5c;
}

.upbox-navbar-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
}

.start-container-navbar {
    margin-left: auto;
	margin-right: auto;
}

.upbox-navbar-item > * {
    display: flex;
    flex-direction: row;
    font-family: Montserrat;
    display: flex;
    font-size: 20px;
    flex-flow: row wrap;
    color: #ccc
}

.upbox-navbar-item > * > * {
    margin: 0 5px;
    font-family: Montserrat;
	font-weight: 700;
	color: #ccc
}

.navbar-user-control-items {
    display: flex;
    flex-direction: row
}

.navbar-left-items {
    font-family: Open Sans,sans-serif;
    font-size: 12px;
    color: #EEEDED;
    user-select: none;
    font-weight: 400;
    align-items: center;
    margin: 0px 13px;
}
