.la-ball-clip-rotate,
.la-ball-clip-rotate > div {
    position: relative;
    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box;
}
.la-ball-clip-rotate {
    display: block;
    font-size: 0;
    color: #19b500;
}
.la-ball-clip-rotate.la-dark {
    color: #333;
    display: flex;
    flex-direction: column;
}
.la-ball-clip-rotate.la-light {
    color: #fff;
    display: flex;
    flex-direction: column;
}
.la-ball-clip-rotate.la-light-blue {
    color: #7bc2ff;
}
.la-ball-clip-rotate > div {
    display: inline-block;
    float: none;
    background-color: currentColor;
    border: 0 solid currentColor;
}
.la-ball-clip-rotate {
    width: 100%;
    height: 89%;
    justify-content: center;
    align-items: center;
    display: flex;
}
.la-ball-clip-rotate > div {
    width: 150px;
    height: 150px;
    background: transparent;
    border-width: 10px;
    border-bottom-color: transparent;
    border-radius: 100%;
    -webkit-animation: ball-clip-rotate .75s linear infinite;
       -moz-animation: ball-clip-rotate .75s linear infinite;
         -o-animation: ball-clip-rotate .75s linear infinite;
            animation: ball-clip-rotate .75s linear infinite;
}
.la-ball-clip-rotate.la-sm {
    width: 160px;
    height: 160px;
}
.la-ball-clip-rotate.la-sm > div {
    width: 160px;
    height: 160px;
    border-width: 1px;
}
.la-ball-clip-rotate.la-2x {
    width: 640px;
    height: 640px;
}
.la-ball-clip-rotate.la-2x > div {
    width: 640px;
    height: 640px;
    border-width: 40px;
}
.la-ball-clip-rotate.la-3x {
    width: 960px;
    height: 960px;
}
.la-ball-clip-rotate.la-3x > div {
    width: 960px;
    height: 960px;
    border-width: 60px;
}
/*
 * Animation
 */
@-webkit-keyframes ball-clip-rotate {
    0% {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    50% {
        -webkit-transform: rotate(180deg);
                transform: rotate(180deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}
@-moz-keyframes ball-clip-rotate {
    0% {
        -moz-transform: rotate(0deg);
             transform: rotate(0deg);
    }
    50% {
        -moz-transform: rotate(180deg);
             transform: rotate(180deg);
    }
    100% {
        -moz-transform: rotate(360deg);
             transform: rotate(360deg);
    }
}
@-o-keyframes ball-clip-rotate {
    0% {
        -o-transform: rotate(0deg);
           transform: rotate(0deg);
    }
    50% {
        -o-transform: rotate(180deg);
           transform: rotate(180deg);
    }
    100% {
        -o-transform: rotate(360deg);
           transform: rotate(360deg);
    }
}
@keyframes ball-clip-rotate {
    0% {
        -webkit-transform: rotate(0deg);
           -moz-transform: rotate(0deg);
             -o-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    50% {
        -webkit-transform: rotate(180deg);
           -moz-transform: rotate(180deg);
             -o-transform: rotate(180deg);
                transform: rotate(180deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
           -moz-transform: rotate(360deg);
             -o-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}
