.add-product-form-content
    padding: 1rem
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    width: 100%
    .input-style-file
        color: #4d4d4d
    .form-input
        display: flex
        justify-content: center
        align-items: center
        width: 100%
        margin: 0 auto
        .add-release--input-title
            font-size: 19px
            font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif
            letter-spacing: 0.5
            display: flex
            align-items: center
            color: #191919
            margin-right: auto
        .product-icon
            +size(9rem)
    .add-product-button-box
        width: 100%
        position: fixed
        bottom: 7px
        right: 0
        width: 100%
        padding-right: 24px
        display: flex
        justify-content: flex-end
        align-items: center
        padding-top: 5px
        padding-bottom: 5px
        background-color: #f3f3f3
        .add-product-button
            width: 125px
            min-height: 35px
            background-color: #4599da !important
            cursor: pointer
            border-radius: 50px
            color: #ffffff
            transition: all 0.15s ease
            border: none
            font-size: 12px
            padding-top: 5px
            padding-bottom: 5px
            background-color: #f3f3f3
            text-decoration: none
            display: inline-block
            transition: all .15s ease
            &.hover
                opacity: 0.8

.input-style-file
    font-size: 12px
    border-radius: 5px
    cursor: pointer
    padding: 10px
    font-weight: bold
    color: #2b2b2b

.add-asset-input-title
    width: 155px
    font-size: 15px
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif
    letter-spacing: 0.5
    color: #2b2b2b

.update--input-title
    width: 155px
    font-size: 15px
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif
    letter-spacing: 0.5
    color: #2b2b2b

.product-row
    display: flex
    flex-direction: row

.footer-button
    padding: 0
