
/* MoreInfo Modal Css - Begin */
.more-form {
    position: absolute;
    width: 650px;
    background-color: #383838;
    margin: 0 auto;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 500px;
    display: flex;
    flex-direction: column;
    box-shadow: 0 1px 40px rgba(0, 0, 0, .9);
    display: flex;
}

.more-form-container-box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}
.more-form-content {
    overflow: auto;
}

.more-form-form-input {
    display: grid;
    grid-template-columns: repeat(2, 30%);
}

.more-form-input-title-row {
    color: #4e4e4e;
    font-size: 18px;
    font-weight: 600;
}

.more-form-input-title {
    color: #4e4e4e;
    font-size: 18px;
    display: flex;
}

.more-form-content-container {
    width: 600px;
    color: #2b2b2b;
}

.form-table-style {
    margin: 1rem;
}

.form-table-style h3 {
    color:#4e4e4e;
}

.release-form-title {
    color: #fff;
    padding:1rem;
}

.form-input {
    display: flex;
    margin-top: 1rem;
}

.form-input-row {
    display: flex;
    flex-direction: row;
    width: 100%;
    position: relative;
}

.form-table-container {
    width:100%
}
/* MoreInfo Modal Css - End */

/* AddAsset Modal Css - Begin */
/* .add-asset-form {
    position: absolute;
    width: 600px;
    background-color: #383838;
    margin: 0 auto;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 900px;
    display: flex;
    flex-direction: column;
    box-shadow: 0 5px 40px rgba(0, 0, 0, .9);
}

.add-asset-button {
    width: 130px;
    height: 40px;
    border-radius: 5px;
    float: left;
    margin: 1.5rem;
    background-color: rgb(7, 143, 233);
    color: #fff;
    border: 1px solid gray;
    padding: 0.5rem;
}
.add-asset-form-content {
    border-bottom: 1px solid #585858;
    border-top: 1px solid #585858;
    background-color: #383838;
    padding-bottom: 5rem;
    display: flex;
}

.asset-form-input {
    display: flex;
    flex-direction: row;
    margin-left: 6rem;
    margin-top: 3rem;
    align-items: center;
}

.input-style-file{
    font-size: 12px;
    border-radius: 5px;
    cursor: pointer;
    padding: 10px;
    font-weight: bold;
    background-color: rgb(71, 71, 71);
    color: rgb(159, 159, 159);
}

input[type="file"]{
    display: none;
}
.add-asset-input-title {
    width: 155px;
    font-size: 21px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    letter-spacing: 0.5;
    color: #fff;
}

.asset-name-span {
    color: #fff;
    font-weight: bold
} */

.combobox-style {
    width: 60%;
    height: 2rem;
    border-radius: 10px;
    height: 4rem;
    margin-top: 1rem;
}
/* AddAsset Modal Css - End */

/* AddNewRelease Modal Css - Begin

.add-release-form-content {
    border-bottom: 1px solid #585858;
    border-top: 1px solid #585858;
    background-color: #383838;
    padding-bottom: 5rem;
    display: flex;
}

.add-release-form-content-row {
    border-bottom: 1px solid #585858;
    border-top: 1px solid #585858;
    background-color: #383838;
    padding-bottom: 5rem;
}

.add-release-form-content-box {
    width: 100%;
}

.add-release-form {
    position: absolute;
    background-color: #383838;
    margin: 0 auto;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 500px;
    display: flex;
    flex-direction: column;
    box-shadow: 0 1px 40px rgba(0, 0, 0, .9);
}

#release:target, #release:target + .add-release-form{
    display:block;
    opacity:0.5;
}

.product-link {
    margin-top: 3rem;
    margin-bottom: 2rem;
    border-bottom: 1px solid #585858;
}

.add-release--input-title.link {
    font-size: 20px;
    font-weight: 400;
    color: #CCCCCC;
}

.add-release--input-title {
    font-size: 18px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    letter-spacing: 0.5;
    display: flex;
    align-items: center;
    color: #fff;
    margin-right: auto;
}
.add-release--input-message {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: #ccc;
    margin-top: -5px;
    margin-bottom: 10px;
}

.add-release--input-title-row {
    font-size: 19px;
    width: 50%;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    letter-spacing: 0.5;
    display: flex;
    align-items: center;
    color: #fff;
    margin-right: 5px;
}

.update-password-show{
    position: absolute;
    color: #383838;
    font-size: 18px;
    margin-top: 20px;
    right: 25px;
}
.password-show{
    font-size: 18px;
    color: #383838;
    position: absolute;
    right: 7px;
    top: 20px;
}

.add-release-form-footer {
    width: 100%;
    background-color: #383838;
    text-align: end;
    padding: 2rem;
}

.add-release-button {
    width: 130px;
    height: 40px;
    border-radius: 5px;
    float: left;
    margin: 1.5rem;
    background-color: rgb(7, 143, 233);
    color: #fff;
    border: 1px solid gray;
    padding: 0.5rem;
}

.add-release-button:hover {
    background: #434343;
    letter-spacing: 0.3px;
    -webkit-box-shadow: 0px 5px 40px -10px rgba(0, 0, 0, 0.57);
    -moz-box-shadow: 0px 5px 40px -10px rgba(0, 0, 0, 0.57);
    box-shadow: 5px 40px -10px rgba(0, 0, 0, 0.57);
    transition: all 0.4s ease 0s;
} */

/* AddNewRelease Modal Css - End */
.footer-button {
    width:100px;
    height: 44px;
    padding: 17px
}

/*Button Begin*/
.cancel-button {
    background-color: rgb(37, 39, 43);
    width: 100px;
    height: 45px;
    border-radius: 5px;
    border: none;
    box-shadow: 0 9px 10px rgba(41, 41, 41, 0.5);
    color: #fff;
}

.cancel-button:hover {

    letter-spacing: 0.5px;
    -webkit-box-shadow: 0px 5px 40px -10px rgba(0, 0, 0, 0.57);
    -moz-box-shadow: 0px 5px 40px -10px rgba(0, 0, 0, 0.57);
    box-shadow: 5px 40px -10px rgba(0, 0, 0, 0.57);
    transition: all 0.4s ease 0s;
}

.delete-button {
    background-color: rgb(133, 133, 133);
    width: 100px;
    height: 45px;
    border-radius: 5px;
    border: none;
    box-shadow: 0 9px 10px rgba(63, 62, 62, 0.5);
    color: #fff;
}
.delete-button:hover {
    background: #434343;
    letter-spacing: 0.3px;
    -webkit-box-shadow: 0px 5px 40px -10px rgba(0, 0, 0, 0.57);
    -moz-box-shadow: 0px 5px 40px -10px rgba(0, 0, 0, 0.57);
    box-shadow: 5px 40px -10px rgba(0, 0, 0, 0.57);
    transition: all 0.4s ease 0s;
}

/* .add-product-button {
    width: 120px;
    height: 35px;
    background-color: #4599da !important;
    cursor: pointer;
    border-radius: 50px;
    color: #ffffff;
    transition: all 0.15s ease;
    border: none;
    font-size: 12px;
    text-align: center;
    position: absolute;
    right: 15px;
    text-decoration: none;
    display: inline-block;
    transition: all .15s ease;
} */

/* .add-product-button:hover {
    opacity: 0.8;
} */

.button:hover {
    background: #434343;
    letter-spacing: 0.3px;
    -webkit-box-shadow: 0px 5px 40px -10px rgba(0, 0, 0, 0.57);
    -moz-box-shadow: 0px 5px 40px -10px rgba(0, 0, 0, 0.57);
    box-shadow: 5px 40px -10px rgba(0, 0, 0, 0.57);
    transition: all 0.4s ease 0s;
}

.add-button {
    width: 140px;
    height: 50px;
    border-radius: 10px;
    float: left;
    margin-top: 1rem;
    background-color: rgb(58, 58, 58);
    color: #fff;
    border: 1px solid gray;
}

.add-button:hover {
    background: #434343;
    letter-spacing: 0.3px;
    -webkit-box-shadow: 0px 5px 40px -10px rgba(0, 0, 0, 0.57);
    -moz-box-shadow: 0px 5px 40px -10px rgba(0, 0, 0, 0.57);
    box-shadow: 5px 40px -10px rgba(0, 0, 0, 0.57);
    transition: all 0.4s ease 0s;
}

.footer-button {
    width:100px;
    padding: 17px
}
/*Button End*/

/* NotFound Begin */
.notfound-upbox-container{
    padding-top: 60px;
    text-align: center;
	height: 100%;
	display: flex;
}

.notfound-upbox-container {
    padding-top: 60px;
    text-align: center;
    padding-right: 0px;
	padding-left: 0px;
	height: 100%;
	display: flex;
}

.notfound-upbox-container h1{
	color: white;
	font-size: 100px;
}

.notfound-upbox-container p {
    color: white;
    font-weight: 100;
    font-size: 31px;
	font-family: Montserrat;
}

.notfound-container {
	margin-left: auto;
	margin-right: auto;
	display: flex;
}

.notfound-content {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.notfound-button {
    margin-top: 30px;
	width: auto;
	min-width: 200px;
	min-height: 50px;
	font-weight: 500;
}
NotFound End

/* Scrollbar Begin */
::-webkit-scrollbar {
    width: 6px;
    height: 8px;
    background-color: rgba(0, 0, 0, 0);
    -webkit-border-radius: 2px;
}

::-webkit-scrollbar-thumb:vertical {
    background: #cecece;
    -webkit-border-radius: 2px;
}

::-webkit-scrollbar-thumb:vertical:hover {
    background: #888888;
}

::-webkit-scrollbar-thumb:vertical:active {
    background: #888888;
    -webkit-border-radius: 2px;
}

::-webkit-scrollbar-thumb:horizontal {
    background: #cecece;
    -webkit-border-radius: 2px;
}

::-webkit-scrollbar-thumb:horizontal:hover {
    background: #888888;
}

::-webkit-scrollbar-thumb:horizontal:active {
    background: #888888;
    -webkit-border-radius: 2px;
}
/* Scrollbar End */
