.add-release-form-content
    border-bottom: 1px solid #f3f3f3
    border-top: 1px solid #f3f3f3
    background-color: #f3f3f3
    .form-input
        display: flex
        justify-content: center
        align-items: flex-start
        width: 100%
        margin: 0 auto

.add-release-form-content-box
    width: 100%

.add-release-form
    position: absolute
    background-color: #383838
    margin: 0 auto
    left: 0
    right: 0
    bottom: 0
    top: 0
    z-index: 500px
    display: flex
    flex-direction: column
    box-shadow: 0 1px 40px rgba(0, 0, 0, 0.9)

#release:target
    display: block
    opacity: 0.5
    .add-release-form
        display: block
        opacity: 0.5

.product-link
    margin-top: 3rem
    margin-bottom: 2rem
    border-bottom: 1px solid #585858

.add-release--input-title
    font-size: 18px
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif
    letter-spacing: 0.5
    display: flex
    align-items: center
    color: #2b2b2b
    &.link
        font-size: 20px
        font-weight: 400
        color: #2b2b2b

.add-release--input-message
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif
    color: #383838
    margin-top: -5px
    margin-bottom: 10px

.add-release--input-title-row
    font-size: 19px
    width: 50%
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif
    letter-spacing: 0.5
    display: flex
    align-items: center
    color: #383838
    margin-right: 5px

.update-password-show
    position: absolute
    color: #383838
    font-size: 18px
    margin-top: 20px
    right: 25px

.password-show
    font-size: 18px
    color: #383838
    position: absolute
    right: 7px
    top: 10px

.add-release-form-footer
    width: 100%
    background-color: #383838
    text-align: end
    padding: 2rem

.add-release-button
    width: 130px
    height: 40px
    border-radius: 5px
    float: left
    margin: 1.5rem
    background-color: rgb(7, 143, 233)
    color: #383838
    border: 1px solid gray
    padding: 0.5rem
    &:hover
        background: #434343
        letter-spacing: 0.3px
        -webkit-box-shadow: 0px 5px 40px -10px rgba(0, 0, 0, 0.57)
        -moz-box-shadow: 0px 5px 40px -10px rgba(0, 0, 0, 0.57)
        box-shadow: 5px 40px -10px rgba(0, 0, 0, 0.57)
        transition: all 0.4s ease 0s

.platform-context
    min-width: 125px
    position: absolute !important
    opacity: 0
    pointer-events: none
    top: 197px !important
    left: 32px !important
    border-radius: 5px
    padding: 10px
    background-color: #fff
    z-index: 999999 !important
    +flexbox(flex, center, center, column)
.react-contextmenu-item
    margin: 5px !important
    border-bottom: 1px solid #f1f1f1
    width: 100%
    text-align: center
    cursor: pointer

.asset-menu
    min-width: 125px
    position: absolute !important
    opacity: 0
    pointer-events: none
    top: 128px !important
    left: 32px !important
    border-radius: 5px
    padding: 5px
    background-color: #fff
    z-index: 999999 !important
    +flexbox(flex, center, center, column)

.select-menu
    height: 35px
    min-width: 125px
    background-color: #4599da!important
    cursor: pointer
    border-radius: 50px
    text-align: center
    box-sizing: border-box
    color: #fff
    transition: all .15s ease
    -webkit-user-select: none
    -moz-user-select: none
    -ms-user-select: none
    user-select: none
    outline: none
    border: none
    padding: 5px
    margin: 5px
    -webkit-box: flex
    -ms-flexbox: flex
    display: flex
    justify-content: center
    align-items: center
    flex-direction: row



