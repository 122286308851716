html, body, #root
    height: 100%
    width: 100%
    scroll-behavior: smooth
    background-color: #ececec
    -webkit-text-size-adjust: 100%
    font-variant-ligatures: none
    -webkit-font-variant-ligatures: none
    text-rendering: optimizeLegibility
    -moz-osx-font-smoothing: grayscale
    font-smoothing: antialiased
    -webkit-font-smoothing: antialiased
    text-shadow: rgba(0, 0, 0, .01) 0 0 1px
    font-size: 14px

.start-upbox-body
    margin: 0
    position: absolute
    top: 0
    left: 0
    right: 0
    bottom: 0
    .header-container
        +size(100% auto)
        // clip-path: ellipse(80% 115% at 50% 0)
        background-image: url("../../image/bg.jpg")
        background-repeat: no-repeat
        background-size: cover
        overflow: hidden
        @include position(relative, -10px 0 0 0)
        @include padding(1.3rem)
        +flexbox(flex,space-between,stretch,row)
        .header-star-img
            +size(100% 100%)
            background-image: url("../../image/star.png")
            background-size: cover
            opacity: 0.3
            animation-direction: alternate
            animation-duration: 100s
            margin: 0
            @include position(absolute, 0 0 0 0)
            @include animation(star 20s linear infinite)
            @include keyframes(star)
                from
                    background-position: 0 300px

                to
                    background-position: 300px 800px
        .header-left-content
            +size(100% 100%)
            +flexbox(flex,null,center,column)
            @include padding(12px null)
            .header-title
                h1
                    font-size: 1.9rem
                    color: #fff
                    font-weight: 500
                    line-height: 2rem
                    text-align: center
                    @include position(relative, 0 0 0 0)
                    &::after
                        content: ""
                        background: #ffae49
                        border-radius: 2rem
                        +size(null 0.2rem)
                        margin: 0 auto
                        @include position(absolute, auto 0 -1rem 0)
            .header-title-content
                p
                    font-size: 1.1rem
                    font-weight: 100
                    color: #ffffff
                    letter-spacing: 2px
                    line-height: 2rem
                    text-align: center
                    @include margin(.3rem 0 0 0)
        // .header-right-content
        //     +size(50% null)
        //     +flexbox(flex,flex-end,center,row)
        //     @include position(relative,  0 0 0 0)
        //     .header-right-img
        //         +size(36rem null)
        //         background-size: 100%
        //         z-index: 1
        //         margin-right: 30px
    .footer
        +size(100% 70px)
        background-color: #2e3f4e
        display: flex
        flex-direction: row
        align-items: center
        .footer-left
            +size(50% 100%)
            padding-left: 50px
            display: flex
            align-items: center
            h4
                color: #ffffff
                font-size: 1.2rem
                font-weight: 200
        .footer-right
            +size(50% 100%)
            padding-right: 50px
            display: flex
            flex-direction: row
            justify-content: flex-end
            align-items: center
            .header-right-img
                +size(2rem)
                margin-right: .5rem
                @include filter(invert(1))

@media screen and (min-width: 200px) and (max-width: 450px)
    .header-title
        text-align: center !important
        h1
            font-size: 2rem !important
            color: #fff
            font-weight: 600
            line-height: 2rem !important
    p
        font-size: 1rem !important
        line-height: 2rem !important
    .footer-left
        h4
            font-size: 1rem !important
    .header-right-img
        width: 1.5rem !important
        height: 1.5rem !important
    .footer-right
        padding-right: 15px !important
    .footer-left
        padding-left: 15px !important
// @media screen and (max-width: 850px)
//     .header-container
//         min-height: 25rem !important
//     .header-title
//         text-align: center !important
//     .header-left-content
//         width: 100% !important
//         display: flex
//         justify-content: center !important
//         align-items: center !important
//         min-width: 320px !important
//         padding: 0 !important
//     .header-right-content
//         display: none !important
//     .header-right-img
//         display: none !important
//     h1
//         font-size: 2rem !important
//         color: #fff
//         font-weight: 600
//         line-height: 2rem !important
//     p
//         font-size: 1.2rem !important




