.upbox-button
    +size(null 35px)
    min-width: 125px
    background-color: #4599da !important
    cursor: pointer
    border-radius: 50px
    text-align: center
    box-sizing: border-box
    color: white
    transition: all 0.15s ease
    user-select: none
    border: none
    outline: none
    border: none
    @include padding(5px)
    @include margin(5px)
    +flexbox(flex,center,center,row)
    &:hover
        opacity: 0.8
    &:active
        opacity: 0.6
    &.close-button
        display: fixed
        margin-right: 13px
    .upbox-button-icon--container
        display: flex
        align-items: center
        color: #fff
    .upbox-button-icon--container-top
        +flexbox(flex,center,center,row)
        .upbox-button-icon
            position: relative
            display: flex
            padding: 0 3px
            margin-right: 3px
            svg
                fill: #fff
        span
            color: #fff
.upbox-button-icon
    margin-top: 3px
    margin-right: 3px


// .login-button
// 	padding: 1.3rem
// 	width: 100%
// 	background-color: rgb(230, 91, 10)
// 	border-radius: 5px
// 	letter-spacing: .1rem
// 	border: none
// 	opacity: 0.9
// 	box-shadow: 0 5px 15px rgba(0, 0, 0, .2)
// 	position: relative
// 	overflow: hidden


// .login-button:before, a:before
// 	content: ''
// 	position: absolute
// 	background-color: #1556da
// 	color: rgb(230, 91, 10)
// 	height: 100%
// 	width: 100%
// 	left: 0
// 	top: 0
// 	transform: rotateX(90deg)
// 	transition: 1s
// 	z-index: -1


// .login-button:hover:before
// 	transform: rotateX(0deg)


// .link-pointer
//     pointer-events: none
//  */
