.login-page
    position: absolute
    top: 0
    left: 0
    right: 0
    bottom: 0
    margin: 0 auto
    .signin-container-box
        display: flex
        justify-content: center
        align-items: center
        height: 100%
        .login-background-img
            position: absolute
            top: 0
            left: 0
            bottom: 0
            right: 0
            margin: 0 auto
            img
                width: 100%
                height: 100%
                opacity: 0.2
        .background-transparent
            width: 100%
            height: 100%
            opacity: 0.2
            background-color: #98d1ff52
            position: absolute
        .signin-container
            margin-left: auto
            margin-right: auto
            z-index: 1
            .signin-content
                display: flex
                flex-direction: column
                box-shadow: 0 7px 19px 0 #c1c1c19e
                border-radius: 5px
                z-index: -1px
                text-align: center
                margin: 2rem auto 1.5rem
                background-color: hsla(0, 0%, 100%, 0.27)
                border: 1.4px solid #ececec96
                padding-left: 2.5rem
                padding-right: 2.5rem
                .logosvg
                    +size(90px)
                    border-radius: 50%
                    background-color: #2179bf
                    margin: -3rem auto 0
                    display: flex
                    justify-content: center
                    align-items: center
                    img
                        +size(65px)
                .upbox-header
                    display: flex
                    flex-direction: column
                    justify-content: center
                    align-items: center
                    padding-top: 3rem
                    padding-bottom: 3rem
                    // background-color: #262729
                    border-radius: 6px
                    text-align: center
                    h1
                        color: #2179bf
                        font-size: 2rem
                        font-family: Montserrat
                    .h1-line
                        +size(60% 2px)
                        background-color: #2179bf
                        text-align: center
                .login-box
                    padding-bottom: 3rem
                    width: 100%
                    // background-color: #262729
                    text-align: center
                    border-radius: 6px
                    display: flex
                    align-items: center
                    flex-direction: column
                    margin-bottom: 1rem
                    p
                        margin-right: 25rem
                        font-size: 1.3rem
                        margin-bottom: 1rem
                    .input-item
                        position: relative
                        width: 100%
                        border-radius: 30px
                        color: #ccc
                        margin-bottom: 1rem
                        border: none
                        margin-top: 1rem
                        background-color: #e8f0fe
                        @include padding(11px 1rem 11px 2rem)
                    .login-button-box
                        width: 100% !important
                        .login-button
                            width: 100%
                            margin: 0 !important
                    .password-item
                        display: flex
                        justify-content: center
                        align-items: center
                        width: 100%
                        border-radius: 30px
                        background-color: #e8f0fe
                        color: #4598da
                        margin-bottom: 2rem
                        border: none
                        margin-top: 1rem
                        .input-password-item
                            width: 70%
                            position: relative
                            border-radius: 5px
                            background-color: #e8f0fe
                            color: #2b2b2b
                            border: none
                            @include padding(11px 1rem 1rem 11px)
                        .login-password-show
                            width: 20%
                            display: flex
                            justify-content: flex-end
a
    color: #000
    text-decoration: none
    &.hover
        text-decoration: none




