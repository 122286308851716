.upbox-popup-wrapper
    position: fixed
    left: 0
    right: 0
    top: 0
    bottom: 0
    margin: auto
    background: rgba(255, 255, 255, 0.7)
    z-index: 1
    display: flex
    flex-direction: row
    align-items: center
    justify-content: center
    .upbox-popup
        background: #f3f3f3
        min-width: 500px
        border-radius: 5px
        padding: 0 10px 0 10px
        box-shadow: 0px 0px 25px -2px rgba(79, 79, 79, 1)
        display: flex
        flex-direction: column
        align-items: stretch
        justify-content: flex-start
        animation: popup-scaling 0.4s ease forwards
        box-sizing: border-box
        .upbox-popup-header
            display: flex
            flex-direction: row
            align-items: center
            justify-content: flex-end
            box-sizing: border-box
            .upbox-popup-header-span
                font-weight: bold
                color: #2b2b2b
                font-size: 25px
        &.upbox-popup-big
            @include padding(1rem)
    .upbox-popup-content
        padding: 8px
        font-size: 14px
        box-sizing: border-box
        color: #ccc
        overflow-y: auto
    .upbox-popup-footer
        padding: 10px 8px
        display: flex
        flex-direction: row
        align-items: center
        justify-content: flex-end
        box-sizing: border-box

@keyframes popup-scaling
  0%
    transform: scale(0)
    opacity: 0

  50%
    transform: scale(1)
    opacity: 0.5

  100%
    transform: scale(1)
    opacity: 1
