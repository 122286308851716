.info
    +size(100% auto)
    background-color: #F6F8FA
    display: flex
    justify-content: center
    align-items: center
    padding-right: 50px
    padding-left: 50px
    position: relative
    top: -30px
    background: transparent
    +flexbox(flex,space-between,center,column)
    .info-container
        +size(100% auto)
        max-width: 960px
        margin: 0 auto
        overflow: hidden
        background-color: #ffffff
        border-radius: 1rem
        padding: 3rem 0
        box-sizing: border-box
        +flexbox(flex,space-between,center,row)
        .info-container-left
            +size(50% null)
            @include padding(2rem)
            +flexbox(flex,flex-start,center,row)
            .info-left-img
                +size(30rem null)
                background-size: 100%
                z-index: 1
        .info-container-right
            +size(50% null)
            @include padding(2rem)
            +flexbox(flex,flex-end,center,column)
            .info-list-title
                font-size: 20px
                line-height: 30px
                padding-bottom: 20px
                color: rgb(68, 68, 68)
                font-weight: 600
            .info-list-items
                padding: 0 20px
                padding-top: 20px
                font-size: 16px
                li
                    padding-bottom: 25px
                    list-style-type: none
                    span
                        padding-top: 10px
                        color: rgb(102, 102, 102)
                    p
                        padding-top: 10px
                        color: rgb(102, 102, 102)
@media screen and (min-width: 200px) and (max-width: 450px)
    .info-container
        min-width: 320px !important
    .info-container-left
        display: none !important
    .info-container-right
        width: 100% !important
        padding: 0 !important
        .info-list-title
            font-size: 20px !important
            line-height: 30px
            padding-bottom: 20px
            color: rgb(68, 68, 68)
            font-weight: 600
            text-align: center
    .info-list-items
        padding: 0 20px
        padding-top: 20px
        font-size: 16px
        li
            padding-bottom: 15px
            list-style-type: none
        span
            color: rgb(102, 102, 102)
        p
            padding-top: 5px
            color: rgb(102, 102, 102)
            text-align: left






