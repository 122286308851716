.release-container
    font-family: Montserrat
    font-weight: 400
    line-height: 1.5
    // background-image: url("../../image/star.png")
    background: #ffffff
    background-size: cover
    background-position: 0 0
    animation-direction: alternate
    animation-duration: 100s
    margin: 0
    display: flex
    flex-direction: column
    min-height: 100% !important
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif
    table, th, td
        // border: 1.5px solid #636363
        border-collapse: collapse
        text-align: center
        color: #656565
        font-size: 15px
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif
    th, td
        padding: 15px

.input-style
    width: 100%
    min-width: 300px
    height: 2.2rem
    border-radius: 3px
    position: relative
    padding: 5px
    border: none
    margin-bottom: 11px
    margin-top: 5px

.textarea-style
    width: 100%
    border-radius: 6px
    border: none
    padding: 5px

.form-input
    display: flex
    flex-direction: column

.form-input-channel
    display: flex
    margin-top: 10%

.add-version-input-title
    width: 155px
    font-size: 19px
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif
    letter-spacing: 0.5
    margin-top: 1rem
    color: #2b2b2b

.add-version-form-footer
    width: 100%
    background-color: #383838
    text-align: end
    padding: 2rem

.add-version-form-content-container
    width: 600px
    margin-left: 2rem
    margin-top: 5rem

.cancel-button
    background-color: rgb(37, 39, 43)
    width: 100px
    height: 45px
    border-radius: 5px
    border: none
    box-shadow: 0 9px 10px rgba(41, 41, 41, 0.5)
    color: #fff
    &:hover
        letter-spacing: 0.5px
        -webkit-box-shadow: 0px 5px 40px -10px rgba(0, 0, 0, 0.57)
        -moz-box-shadow: 0px 5px 40px -10px rgba(0, 0, 0, 0.57)
        box-shadow: 5px 40px -10px rgba(0, 0, 0, 0.57)
        transition: all 0.4s ease 0s

.delete-button
    background-color: rgb(133, 133, 133)
    width: 100px
    height: 45px
    border-radius: 5px
    border: none
    box-shadow: 0 9px 10px rgba(63, 62, 62, 0.5)
    color: #fff
    &:hover
        background: #434343
        letter-spacing: 0.3px
        -webkit-box-shadow: 0px 5px 40px -10px rgba(0, 0, 0, 0.57)
        -moz-box-shadow: 0px 5px 40px -10px rgba(0, 0, 0, 0.57)
        box-shadow: 5px 40px -10px rgba(0, 0, 0, 0.57)
        transition: all 0.4s ease 0s

.more-form
    position: absolute
    width: 650px
    background-color: #383838
    margin: 0 auto
    left: 0
    right: 0
    bottom: 0
    top: 0
    z-index: 500px
    display: flex
    flex-direction: column
    box-shadow: 0 1px 40px rgba(0, 0, 0, 0.9)
    display: none

.more-form-content
    background-color: #f3f3f3
    overflow: auto
    table, th, td
        border: 1.5px solid #636363
        border-collapse: collapse
        text-align: center
        color: #4e4e4e
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif
    th, td
        padding: 10px

.form-table-style
    margin: 1rem

.add-asset-form
    position: absolute
    width: 600px
    background-color: #383838
    margin: 0 auto
    left: 0
    right: 0
    bottom: 0
    top: 0
    z-index: 900px
    display: flex
    flex-direction: column
    box-shadow: 0 5px 40px rgba(0, 0, 0, 0.9)
    display: none

.version-form-title
    color: #fff
    padding: 1rem

.release-list-container
    flex-direction: column
    justify-content: center
    align-items: center
    display: flex
    width: 100%
    position: relative

.disabled-container
    position: absolute
    left: 0
    right: 0
    top: 0
    bottom: 0
    background: #cccccc4d
    z-index: 9999

.release-container-up-header
    height: auto
    width: 100%
    background-image: url("../../image/bg.jpg")
    background-repeat: no-repeat
    background-size: cover
    position: relative
    background-repeat: no-repeat
    overflow: hidden
    padding: 3rem
    color: #ffffff
    +flexbox(flex, space-between, center, row)
    .release-header-star-img
        +size(100% 100%)
        background-image: url("../../image/star.png")
        background-size: cover
        opacity: 0.3
        animation-direction: alternate
        animation-duration: 100s
        margin: 0
        @include position(absolute, 0 0 0 0)
        @include animation(star 20s linear infinite)
        @include keyframes(star)
            from
                background-position: 0 300px

            to
                background-position: 300px 800px
    .release-container-header-text-icon-container
        display: flex
        justify-content: center
        align-items: center
        .release-container-header-text
            font-size: 1.9rem
            color: #fff
            font-weight: 500
            line-height: 2rem
            text-align: center
            @include position(relative, 0 0 0 0)
        .release-container-header-icon
            width: 100px
            height: 100px
            border-radius: 50px
            +flexbox(flex, center, center, row)
            .product-icon
                +size(100px null)
.add-version-button-container
    display: flex
    justify-content: center
    .add-version-button
        height: 35px
        width: 125px
        background-color: #4599da!important
        cursor: pointer
        border-radius: 50px
        text-align: center
        box-sizing: border-box
        color: #fff
        transition: all .15s ease
        outline: none
        border: none
        @include margin(5px)
        @include padding(0)
        +flexbox(flex,center,center,row)
        &:hover
            background: #434343
            box-shadow: 5px 40px -10px rgba(0, 0, 0, 0.57)
            transition: all 0.4s ease 0s

.release-channel-header
    display: flex
    flex-direction: row
    align-items: center
    padding-bottom: 10px
    justify-content: space-between

.release-container-content
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center

.release-content
    min-width: 58%

.table-container
    display: flex
    flex-direction: column
    width: 100%

th, td, tr
    border: none !important

table
    border-collapse: collapse
    border-radius: 21px
    border-style: hidden

.table-content
    width: 100%
    border-collapse: collapse
    border-radius: 21px
    border-style: hidden
    background: #f5f5f5

.table-title-item
    display: flex
    justify-content: center
    align-items: center

.more-button
    width: 120px
    height: 35px
    &:hover
        transition: all 0.4s ease 0s

.not-found-component-container
    width: 80%
    display: -ms-flexbox
    display: flex
    margin-left: auto
    margin-right: auto
    border-radius: 10px
    justify-content: center

.not-found-component-header
    font-size: 20px
    color: #ccc
    font-weight: 700

.release-pagination-container
    display: flex
    flex-direction: row
    justify-content: center
    align-items: center

.release-pagination-container-ul
    display: flex
    list-style-type: none
    margin-top: 18px

.release-pagination-container-li
    border: 1px #ccc
    padding: 1px

.page-link
    border-radius: 5px
    float: left
    padding: 6px 12px
    text-decoration: none
    color: #fff
    font-size: 14px

// &:hover:not(.release-pagination-active)
//     background-color: white
//     opacity: 0.7
//     color: black
//     border-radius: 5px

.release-pagination-active
    background-color: #474747
    text-decoration: none
    color: white

@media screen and (min-width: 576px)
    html
        font-size: 7px

@media screen and (min-width: 768px)
    html
        font-size: 8px

@media screen and (min-width: 992px)
    html
        font-size: 9px

.container
    max-width: 960px

.logosvg
    width: 55
    height: 55
    border-radius: 50%
    background-color: #EB6E08
    margin: 0 auto
    margin-top: 1rem
    padding: 0.2rem
