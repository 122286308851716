.update-release-form-content-row
    border-bottom: 1px solid #f3f3f3
    border-top: 1px solid #f3f3f3
    background-color: #f3f3f3
    .form-input
        display: flex
        justify-content: center
        align-items: flex-start
        width: 100%
        margin: 0 auto
        .update-release--input-title
            font-family: -apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Open Sans,Helvetica Neue,sans-serif
            letter-spacing: .5
            display: flex
            align-items: center
            color: #2b2b2b
            margin-right: auto
            font-size: 15px
