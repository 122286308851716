.admin-profile-box
    margin: 0
    position: absolute
    top: 0
    left: 0
    right: 0
    bottom: 0
    background-color: #ffffff
    .admin-panel-button
        font-size: 20px
    .admin-profile-container
        +size(100%)
        display: flex
        align-items: center
        .admin-profile-container-box
            +size(20% 100%)
            min-width: 350px
            box-shadow: 0px 0px 10px -1px #b8b8b8
            z-index: 1
            .admin-profile-vcard-box-container
                +size(100% 100%)
                display: flex
                justify-content: center
                .admin-profile-vcard
                    +size(100%)
                    background: #f5f5f5
                    border-radius: 10px
                    +flexbox(flex, flex-start, center, column)
                    .admin-profile-vcard-avatar
                        +size(100% null)
                        background-image: url("../../image/avatar-bg.jpg")
                        @include padding(5rem)
                        +flexbox(flex, center,center, column)
                        img
                            +size(130px)
                            padding: .4rem
                            background-color: #fff
                            border-radius: 100%
                            border: 3px solid #82a1bd
                        .admin-name
                            font-size: 1.7rem
                            color: #4a4a4a
                            font-weight: 200!important
                            margin: 10px
                            border-top: 1px solid #6b6b6b
                            border-bottom: 1px solid #6b6b6b
                            width: 100%
                            display: flex
                            justify-content: center
                    .admin-profile-vcard-box
                        +size(100% null)
                        +flexbox(flex, flex-start, center, row)
                        @include padding(2rem 1rem)
                        max-width: 356px
                        img
                            +size(20px)
                            @include margin(0 10px)
                        .admin-profile-vcard-name
                            border-bottom: 1px solid gray
                            +flexbox(flex, flex-start,center, row)
                        .admin-profile-vcard-email
                            font-size: 1.4rem
                            color: grey
                            font-weight: 200!important
                            +flexbox(flex, center,center, column)
                        .admin-mail
                            font-size: 1.3rem
                            font-weight: 300
                            border-bottom: 1px solid gray
                            width: 100%
                            padding-bottom: .5rem
                            margin-bottom: .5rem
                            color: #525252
                    .admin-profile-edit-button
                        margin: 15px
                        display: flex
                        justify-content: flex-end
        .admin-list-container
            +size(80% 100%)
            min-width: 600px
            display: flex
            justify-content: center
            overflow-y: auto
            .admin-list-container-box
                margin: 20px
                width: 100%
                min-width: 450px
            .admin-list-header
                font-size: 20px
                color: #8b8b8b
                display: flex
                justify-content: space-between
                flex-direction: row
                border-bottom: 1px solid #ccc
                .admin-header-right
                    +flexbox(flex, center, center, row)
                    @include margin(0 3px 0 0)
                    .admin-profile-added-admin
                        flex-direction: row
                        display: flex
                        justify-content: space-between
                        .upbox-button-icon--container
                            font-size: 15px
                    .upbox-dropdown-container
                        &.navbar-header-dropdown
                            font-size: 15px
                        span
                            font-size: 15px
            .admin-list-box
                .admin-list-item
                    display: flex
                    flex-direction: row
                    justify-content: space-between
                    border-bottom: 1px solid #ccc
                    user-select: none
                    @include padding(10px 0px 2px 0px)
                    .admin-list-item-avatar
                        +size(50px)
                        padding: .2rem
                        background-color: #fff
                        border-radius: 100%
                        border: 2px solid #82a1bd
                    .admin-list-item-info
                        display: flex
                        justify-content: center
                        flex-direction: column
                        margin-left: 20px
                        margin-right: 25px
                        width: 85%
                        color: #a29b9b
                        font-weight: bold
                        .admin-list-item-info-title
                            color: #656565
                            font-size: 15px
                    .admin-list-item-remove-button
                        display: flex
                        justify-content: center
                        align-items: center
                        border-radius: 15px







