@font-face
    font-family: 'SF Pro Display'
    src: url('../../fonts/sf/SF-Pro-Display-Regular.otf')
    font-weight: 300
@font-face
    font-family: 'SF Pro Display'
    src: url('../../fonts/sf/SF-Pro-Display-Light.otf')
    font-weight: 200
@font-face
    font-family: 'SF Pro Display'
    src: url('../../fonts/sf/SF-Pro-Display-Semibold.otf')
    font-weight: 400
@font-face
    font-family: 'SF Pro Display'
    src: url('../../fonts/sf/SF-Pro-Display-Medium.otf')
    font-weight: 500

@font-face
    font-family: 'SF Pro Text'
    src: url('../../fonts/sf/SF-Pro-Text-Regular.otf')
    font-weight: 300
@font-face
    font-family: 'SF Pro Text'
    src: url('../../fonts/sf/SF-Pro-Text-Light.otf')
    font-weight: 200
@font-face
    font-family: 'SF Pro Text'
    src: url('../../fonts/sf/SF-Pro-Text-Semibold.otf')
    font-weight: 400
@font-face
    font-family: 'SF Pro Text'
    src: url('../../fonts/sf/SF-Pro-Text-Medium.otf')
    font-weight: 500
@font-face
    font-family: 'SF Pro Text'
    src: url('../../fonts/sf/SF-Pro-Text-Bold.otf')
    font-weight: 600


*
   @include box-sizing(border-box)
   margin: 0
   padding: 0

body
   font-family: SF Pro Display !important
