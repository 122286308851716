.product
    +size(100% auto)
    background-color: #F6F8FA
    display: flex
    justify-content: center
    align-items: center
    padding-right: 50px
    padding-left: 50px
    position: relative
    top: -30px
    background: transparent
    .product-container
        +size(100% auto)
        max-width: 960px
        margin: auto
        overflow: hidden
        background-color: #ffffff
        border-radius: 1rem
        padding: 1rem 0
        .product-buttons
            +size(100% null)
            +flexbox(flex,center,stretch,row)
        .product-grid
            display: flex
            // flex-wrap: wrap
            flex-direction: column
            justify-content: center
            align-items: center
            @include padding(.5rem 1.7rem)
            .product-container-content
                +size(100% auto)
                display: flex
                flex-direction: row
                background-color: #f5f5f5
                border-radius: 15px
                position: relative
                margin: 5px
                padding: 1rem
                .product-box-left
                    +size(20% null)
                    max-width: 150px
                    min-width: 120px
                    display: flex
                    flex-direction: column
                    justify-content: flex-start
                    align-items: center
                    padding: .5rem
                    .product-icon
                        +size(5rem)
                        padding: .2rem
                        background-color: #fff
                        border-radius: 100%
                        border: 3px solid #82A1BD
                    p
                        font-weight: 200 !important
                    .product-name
                        +flexbox(flex, center, flex-start, row)
                        img
                            height: 18px
                            margin-top: 4px
                            margin-right: 3px
                .product-box-right
                    +size(80% 100%)
                    display: flex
                    flex-direction: column
                    justify-content: space-between
                    p
                        font-size: 1rem
                        color: gray
                        font-weight: 200 !important
                    .product-info-top
                        +size(100% 80%)
                        +flexbox(flex, center, flex-start, column)
                        .upbox-button
                            .product-more-button
                                margin: 0 !important
                                border-radius: 0 !important
                            h3
                                +size(100% null)
                                text-align: center !important
                                font-size: 1.7rem
                                font-weight: 500
                                position: relative
                                display: flex
                                flex-wrap: wrap
                            p
                                font-size: 1.2rem
                                font-weight: 200
                                padding-top: 1.5rem
                        .product-download-btn
                            +size(100% null)
                            +flexbox(flex,center,flex-start,column)
                            .links
                                +size(100% null)
                                .product-download-links
                                    width: 100%
                                    border-radius: 1rem
                                    +flexbox(flex,flex-start,flex-start,column)
                                    z-index: 2
                                    p
                                        margin-top: 10px
                                        margin-bottom: 10px
                                    .download-links
                                        +size(100% null)
                                        display: flex
                                        flex-direction: column
                                        min-width: 138px
                                        align-items: center
                                        color: #000 !important
                                        padding-bottom: 5px
                                        +flexbox(flex,flex-start,center,row)
                                        a
                                            font-weight: 300 !important
                                        .links-content
                                            +size(100% null)
                                            border-bottom: 1px solid #eaeaea
                                            @include margin(5px 0)
                                            @include padding(5px 0)
                                            +flexbox(flex,flex-start,center,row)
                                            .links-name
                                                +size(100% null)
                                            .info-icon-circle
                                                +size(2rem)
                                                min-width: 2rem !important
                                                background-color: #bcdfe873
                                                border-radius: 100%
                                                +flexbox(flex, center, center, row)
                                                .info-icon
                                                    font-size: 18px
                                                    cursor: pointer
                                                    background-color: transparent !important
                                                    color: #000000 !important
                                            .link-icon-circle
                                                +size(2rem)
                                                min-width: 2rem
                                                background-color: #bcdfe873
                                                border-radius: 100%
                                                +flexbox(flex, center, center, row)
                                                @include margin(null 5px null null)
                                                .link-icon
                                                    +size(1.5rem)
                                                    +flexbox(flex,center,center,row)
                                                a
                                                    margin: 5px
                                            &:hover
                                                a
                                                    color: #1b81a6 !important

                            .button-icon
                                +size(100% null)
                                +flexbox(flex,space-between,center,row)
                                border-bottom: 1px solid #eaeaea
                                .button-icon-left
                                    +size(100% null)
                                    +flexbox(flex, flex-start, center, row)
                                    .download-icon-circle
                                        +size(2rem)
                                        min-width: 2rem
                                        background-color: #bcdfe873
                                        border-radius: 100%
                                        +flexbox(flex, center, center, row)
                                        @include margin(null 5px null null)
                                        .download-icon
                                            +size(1.5rem)
                                    a
                                        +size(100% null)
                                    .upbox-button
                                        &.product-more-button
                                            +size(100% null)
                                            margin: 0
                                            border-radius: 0
                                            color: #000000 !important
                                            font-weight: 300 !important
                                            background-color: transparent !important
                                            margin-bottom: 3px
                                            display: flex
                                            justify-content: flex-start !important
                                            &:hover
                                                color: #1b81a6 !important
                                            &.disabled
                                                color: #a8a8a8 !important
                                                background-color: transparent !important
                                                font-weight: bold
                                                cursor: default !important
                                                pointer-events : none !important
                                .info-icon-circle
                                    +size(2rem)
                                    min-width: 2rem
                                    background-color: #bcdfe873
                                    border-radius: 100%
                                    +flexbox(flex, center, center, row)
                                    .info-icon
                                        font-size: 18px
                                        cursor: pointer
                                        background-color: transparent !important
                                        color: #000000 !important

                    .product-info-bottom
                        +size(100% 10%)
                        margin-top: 15px
                        +flexbox(flex, center, flex-start, column)
                        @include position(relative, null null null null)
                        p
                            text-align: left
                        &::before
                            content: "Ürün Açıklaması"  
    



@media screen and (min-width: 200px) and (max-width: 450px)
    .product-grid
        display: flex
        flex-wrap: wrap !important
        justify-content: center !important
        align-content: center !important
    .product-container
        +size(100% auto)
        min-width: 320px !important
        margin: auto
        overflow: hidden
        background-color: #ffffff
        border-radius: 1rem
        padding: 1rem 0
    .product-container-content
        width: 100% !important
        min-height: 340px
        background-color: #f5f5f5
        border-radius: 15px
        padding: 3rem
        position: relative
        box-shadow: 0 5px 15px #64646433
        margin: 5px
        display: flex
        flex-direction: column !important
        align-items: center
        justify-content: center
    .product-info
        +size(100% 70%)
        display: flex
        flex-direction: column !important
        justify-content: center !important
        align-items: center !important
    .product-download-btn
        +size(100%)
        display: flex !important
        flex-direction: column !important
        align-items: center !important
        justify-content: center
        margin-bottom: 0 !important
    .product-box-left
        h3
            text-align: center
    .product-box-right
        width: 100%
        display: flex
        flex-direction: column !important
        justify-content: center !important
        align-items: center !important
    p
        text-align: center
    .download-link-btn
        border-radius: 5px !important
        margin: 5px !important
    .product-more-button
        margin: 5px !important
        border-radius: 5px !important




