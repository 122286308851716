.update-release-form-content
    padding: 1rem
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    width: 100%
    .input-style-file
        color: #4d4d4d
    .form-input
        display: flex
        justify-content: center
        align-items: center
        width: 100%
        margin: 0 auto
        .add-release--input-title
            font-size: 18px
            font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif
            letter-spacing: 0.5
            display: flex
            align-items: center
            color: #191919
            margin-right: auto
        .product-icon
            +size(10rem)
.footer-button
    padding: 0
