* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
}

html {
	font-size: 10px;
}

body {
	font-family: Open Sans, sans-serif;
	font-size: 1.6rem;
	font-weight: 400;
	line-height: 1.5;
	background-position: 0 0;
	background-color: rgb(64,65,66);
	background-size: cover;
	margin: 0;
}

/* .signin-container-box {
	display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.signin-container {
	margin-left: auto;
	margin-right: auto;
}


.header {
	display: flex;
	flex-flow: row wrap;
}

.upbox-headerbox {
	float: left;
	display: flex;
	flex-direction: row;
}

.signin-content{
	margin: 0 auto;
	width: 350px;
	background-color: #262729;
	display: flex;
	flex-direction: column;
	box-shadow: 0 7px 19px rgba(0, 0, 0, .9);
	border-radius: 5px;
	z-index: -1px;
	text-align: center;
	margin-bottom: 1.5rem;
	margin-top: 2rem;
}

.upbox-header {
	display: flex;
	flex-direction: row;
	padding-top: 5rem;
	padding-bottom: 3rem;
	padding-left: 2rem;
	background-color: #262729;
	border-radius: 6px;
	text-align: center;
}

.upbox-header h1 {
	color: rgb(235, 110, 8);
	font-size: 2.5rem;
	font-family: Montserrat;
	margin-right: 2rem;
}

.login-box {
	padding-bottom: 4rem;
	width: 100%;
	background-color: #262729;
	text-align: center;
    border-radius: 6px;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.login-box p {
	margin-right: 25rem;
	font-size: 1.3rem;
	margin-bottom: 1rem;
}

.password-forgot {
    color:#fff;
    margin-left:170px;
	font-size: 1.3rem;
}

.input-item {
	position: relative;
	padding: 1.2rem;
	width: 80%;
	box-shadow: 0 5px 15px rgba(0, 0, 0, .5);
	border-radius: 5px;
	background-color: #303133;
	color: #ccc;
	margin-bottom: 2rem;
	border: none;
	margin-top: 1rem;
}

.input-item-box a {
	margin-left: 150px;
	width: 100%;
	font-size: 12px;
	text-decoration: none;
	color: #fff;
}

.input-item-box a:hover {
	border-bottom: .5px solid #fff;
}

a {
    color: #000;
	text-decoration: none;
}

a:hover {
	text-decoration: none;
}

.input-password-item {
	position: relative;
    padding: 1.2rem;
    width: 100%;
    border-radius: 5px;
    background-color: #303133;
    color: #ccc;
    border: none;
}

.password-item {
	display: flex;
    justify-content: center;
    align-items: center;
    width: 80%;
    box-shadow: 0 5px 15px rgba(0, 0, 0, .5);
    border-radius: 5px;
    background-color: #303133;
    color: #ccc;
    margin-bottom: 2rem;
    border: none;
    margin-top: 1rem;
}
.upbox-headersvg {
	width: 90;
	height: 90;
	border-radius: 50%;
	background-color: #EB6E08;
	margin: 0 auto;
	margin-top: -4rem;
}

.logosvg {
    width: 90;
	height: 90;
	border-radius: 50%;
	background-color: #EB6E08;
	margin: 0 auto;
	margin-top: -4rem;
}

.login-password-show {
	width: 20%;
	display: flex;
	justify-content: center;
} */
